import React, { Component } from 'react'
import Cmp from './SectionStaffList.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpStaff(sort: {fields: title, order: ASC}) {
              edges {
                node {
                  title
                  acf {
                    job_title
                    phone_number
                    email
                    location
                    ssn
                    description
                    image {
                      localFile {
                        childImageSharp {
                          sizes {
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Cmp data={data} {...this.props} />
        )}
      />
    )
  }
}

export default Connected
