import React, { useContext } from 'react'
import styles from './StaffListItem.module.scss'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { TRANSLATIONS } from '../../misc/translations.js'
import { LanguageContext } from '../../context'

const StaffListItem = ({ img, name, title, location, email }) => {
  const containerClass = classNames(
    styles.container,
    'd-md-flex',
    'justify-content-between',
    'bg-graygrad',
    'mb-3',
    'text-center',
    'text-md-left'
  )

  const textAreaClass = classNames(
    styles.text,
    'd-flex',
    'flex-column',
    'flex-grow-1',
    'justify-content-center',
    'pl-lg-4',
    'pl-3',
    'pr-3',
    'pr-lg-0',
    'pb-md-3',
    'pt-3'
  )

  const emailAreaClass = classNames(
    styles.email,
    'd-flex',
    'flex-column',
    'justify-content-center',
    'px-lg-4',
    'px-2',
    'pb-3',
    'pb-md-0'
  )

  const imageClass = classNames(
    styles.image,
    'pt-3',
    'pt-md-0'
  )

  const imageStyle = {
    backgroundImage: `url(${img})`
  }

  const mailLink = 'mailto:' + email
  const mailString = email ? email.replace('@ljosleidarinn.is', '') : null
  // temp until language is ready
  const currentLang = useContext(LanguageContext).currentLang
    ? useContext(LanguageContext).currentLang
    : 'is'

  return (
    <div className={containerClass}>
      <div className={imageClass} style={imageStyle} />
      <div className={textAreaClass}>
        <h2 className='hdln--3 mb-1'>{name}</h2>
        <div><span>{title}</span><span>{location}</span></div>
      </div>
      <div className={emailAreaClass}>
        {/* Temp hjá until lang context is ready for this */}
        {mailString && mailLink &&
          <a href={mailLink}><strong>{mailString}</strong> {TRANSLATIONS[currentLang].AT} <strong>ljosleidarinn.is</strong></a>
        }
      </div>
    </div>
  )
}

export default StaffListItem

StaffListItem.propTypes = {
  img: propTypes.string,
  name: propTypes.string.isRequired,
  title: propTypes.string,
  location: propTypes.string,
  email: propTypes.string.isRequired
}

StaffListItem.defaultProps = {
  img: 'https://www.on.is/sites/on.is/files/holmfridur-sig-web-2_0.jpg' // todo: generic default mynd
}
