import React, { Component } from 'react'
import StaffListItem from '../../StaffListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { TRANSLATIONS } from '../../../misc/translations.js'
import { get } from 'lodash'

class SectionStaffList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filteredItems: [],
      items: this.props.data.allWordpressWpStaff ? this.props.data.allWordpressWpStaff.edges : []
    }

    this.filterItems = this.filterItems.bind(this)
  }

  staffSort (a, b) {
    return a.node.title.localeCompare(b.node.title)
  }

  componentWillMount () {
    const staffItems = this.props.data.allWordpressWpStaff ? this.props.data.allWordpressWpStaff.edges : []
    const staffItemsSorted = staffItems.sort(this.staffSort)

    this.setState({
      items: staffItemsSorted,
      filteredItems: staffItemsSorted
    })
  }

  filterItems (e) {
    const value = e.currentTarget.value
    const filteredItems = this.state.items.filter(item => {
      return (
        item.node.title.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        item.node.acf.job_title.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        item.node.acf.location.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        item.node.acf.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
      )
    })

    this.setState({
      filteredItems: filteredItems
    })
  }

  render () {
    const filteredItems = this.state.filteredItems
    const currentLang = this.props.currentLang ? this.props.currentLang : 'is'

    return (
      <section className='container pt-lg-6 pt-md-5 pt-3' aria-label='Staff list'>
        <div className='row'>
          <div className='col-lg-6 offset-lg-3 d-flex align-items-center'>
            <input
              onChange={this.filterItems}
              className='search--form-control w-100 pr-md-5 mb-0'
              type='text'
              placeholder={TRANSLATIONS[currentLang].SEARCH_STAFF}
              title={TRANSLATIONS[currentLang].SEARCH_STAFF}
            />
            <div className='button--search-icon d-flex justify-content-center align-items-center'>
              <FontAwesomeIcon icon={faSearch} role='presentation' />
            </div>
          </div>
        </div>

        <div className='row pt-5'>
          <div className='col-lg-10 offset-lg-1'>
            { filteredItems && filteredItems.map((item, i) => {
              return <StaffListItem
                key={i}
                img={get(item, 'node.acf.image.localFile.childImageSharp.sizes.src', '')}
                name={item.node.title}
                title={item.node.acf.job_title}
                location={item.node.acf.location}
                email={item.node.acf.email}
              />
            })
            }
          </div>
        </div>
      </section>
    )
  }
}

export default SectionStaffList
