import React, { useContext } from 'react'
import MainLayout from '../../components/MainLayout'
import SectionStaffList from '../../components/Sections/SectionStaffList'
import { LanguageContext } from '../../context'

const Cmp = data => {
  const currentLang = useContext(LanguageContext).currentLang
    ? useContext(LanguageContext).currentLang
    : 'is'
  return (
    <MainLayout topSpacing>
      <SectionStaffList currentLang={currentLang} />
    </MainLayout>
  )
}

export default Cmp
