import Cmp from './Starfsmenn.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query starfsmennPageQuery {
    allWordpressAcfStaff {
      edges {
        node {
          id
          acf {
            description
            ssn
            image {
              source_url
            }
            email
            job_title
            location
            phone_number
          }
          wordpress_id
        }
      }
    }
  }
`

export default Cmp
